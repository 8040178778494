/* eslint-disable no-unused-vars */
import axios from 'axios';

const cacheExpiry = 5 * 60 * 1000; // 5 minutes

export async function getGravatarData(profileIdentifier) {
    try {
        if (!profileIdentifier) return;

        const cacheKey = `gravatarDetailedProfile-${profileIdentifier}`;
        const cachedProfile = localStorage.getItem(cacheKey);

        if (cachedProfile) {
            const { profile, timestamp } = JSON.parse(cachedProfile);
            if (Date.now() - timestamp < cacheExpiry) {
                // eslint-disable-next-line no-console
                console.log('Using Gravatar detailed data from cached memory.');
                return profile;
            }
        }

        const response = await axios.get(
            `https://api.gravatar.com/v3/profiles/${profileIdentifier}`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_GRAVATAR_API_KEY}`,
                },
            }
        );

        let {
            display_name,
            avatar_url,
            job_title,
            profile_url,
            links,
            verified_accounts,
        } = response.data;

        // Check if the avatar is the default image.
        const imgUrl = `${avatar_url}?d=404`;
        try {
            await axios.get(imgUrl);
            // If the request succeeds, it means there is a custom Gravatar image
        } catch (error) {
            // If the request fails with a 404, it means the image is the default one.
            if (error.response && error.response.status === 404) {
                avatar_url = undefined;
            } else {
                // eslint-disable-next-line no-console
                console.error('Error fetching the image:', error);
            }
        }

        const getLinkedinUrl = () => {
            const linkedin = links?.find(
                (link) => link?.label.toLowerCase() === 'linkedin'
            );
            let linkedin_url;

            if (!linkedin) {
                const verifiedLinkedin = verified_accounts?.find(
                    (link) => link?.service_type.toLowerCase() === 'linkedin'
                );
                linkedin_url = verifiedLinkedin?.url;
            } else {
                linkedin_url = linkedin?.url;
            }

            profile_url = linkedin_url ? linkedin_url : profile_url;
            return { display_name, avatar_url, job_title, profile_url };
        };

        // To use Linkedin profile url instead of Gravatar profile url.
        // const profileData = getLinkedinUrl()

        // To use Gravatar profile url.
        const profileData = {
            display_name,
            avatar_url,
            job_title,
            profile_url,
        };

        localStorage.setItem(
            cacheKey,
            JSON.stringify({ profile: profileData, timestamp: Date.now() })
        );

        return profileData;
    } catch (error) {
        return {
            error: error.response ? error.response.statusText : 'An error occurred',
        };
    }
}
