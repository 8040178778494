import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MissionItem from './MissionItem';
import './Mission.css';

// Import SVG icons
import relevanceIcon from '../../assets/img/icons/svg/relevance.svg';
import impactIcon from '../../assets/img/icons/svg/impact.svg';
import methodsIcon from '../../assets/img/icons/svg/methods.svg';

const Mission = () =>
    <section id="mission" className="py-5 bg-light">
        <Container className="mission-container">
            <Row className="mb-5 mission-heading-container px-3">
                <Col>
                    <h2 className="h1 text-center text-primary">Our Mission</h2>
                    <p className="mission-subheading fs-4">
              Our mission is to make available all of the existing work around
              sustainability in Colorado within one platform, while also
              providing the capability to give a voice to groups that might not
              have representation in existing research and policy making.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <MissionItem
                        icon={relevanceIcon}
                        title="Relevance"
                        description="Colorado is an important geographic region, developing rapidly across critical systems connecting
                        urban and rural communities. Existing efforts to understand and increase sustainability are often siloed by sectors,
                        causing gaps in our ability to integrate them at a regional scale."
                    />
                    <MissionItem
                        icon={impactIcon}
                        title="Impact"
                        description="The Sustainability Hub will provide a common platform for researchers, policy makers, and community members
                         to discover connections, and contribute new data. We aim to build a social data hub that is equitable, inclusive, and
                         freely accessible for all potential users."
                    />
                    <MissionItem
                        icon={methodsIcon}
                        title="Methods"
                        description="Our goal is to create a natural language based query system that is approachable to anyone, leveraging
                         advanced machine learning techniques. This data must be representative of all communities within the state,
                          so we will be partnering with citizen science projects."
                    />
                </Col>
            </Row>
        </Container>
    </section>
  ;

export default Mission;
