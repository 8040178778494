import React from 'react';
import { Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Researchers.css';

export default function Researchers({ imgSrc, name, title, university, link }) {
    return (
        <Col lg={2} md={3} xs={4} className="researchers-col">
            <Card id="researchers-card" className="researchers-card-container pb-4">
                <Card.Img
                    className="rounded-circle"
                    variant="top"
                    src={`${imgSrc}?size=500`}
                    alt={`${imgSrc.startsWith('http') ? name : `No profile picture for ${name}`}`}
                    style={{ width: '120px', height: '120px' }}
                />
                <Card.Body className="py-2">
                    <Card.Title className="name text-center">
                        {link
                            ?
                            <a
                                href={link}
                                className="link-dark link-offset-2 text-wrap fw-normal link-underline-opacity-25 link-underline-opacity-100-hover h6"
                            >
                                {name}
                                <span className="visually-hidden">
                Click to see profile of {name}.
                                </span>
                            </a>
                            : <span className="text-dark text-center text-wrap fw-normal h6">
                                {name}
                            </span>
                        }
                    </Card.Title>
                    <Card.Text className="title text-dark text-center fw-normal h6">
                        <div>{title}</div>
                        <div>{university}</div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
}

Researchers.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    university: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    link: PropTypes.string,
};
