import React from 'react';
import { Container } from 'react-bootstrap';

import './CommunityEvents.css';
import events from '../assets/event/events.json';

import EventCard from './EventCard';

export default function CommunityEvents() {
    return (
        <section id="communityEvents">
            <Container className="communityEvents-container py-4">
                <h2 className="communityEvents-title h1 text-center mb-5">
          Community Events
                </h2>
                {events
                    .slice()
                    .reverse()
                    .map((event, index) =>
                        <EventCard
                            key={index}
                            index={index + 1}
                            day={event.date.day}
                            monthYear={event.date.monthYear}
                            title={event.title}
                            description={event.description}
                            detail={event.detail}
                        />
                    )}
            </Container>
        </section>
    );
}
