import React from 'react';
import Mission from './mission/Mission';
import Definition from './definition/Definition';
import Team from './team/Team';
import Values from './values/Values';
import Acknowledgments from './acknowledgments/Acknowledgments';

const About = () =>
    <div>
        <Mission />
        <Values />
        <Definition />
        <Team />
        <Acknowledgments />
    </div>
  ;

export default About;
