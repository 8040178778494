import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import sustainabilityHubLogo from '../assets/img/logos/png/blueBackground.png';
import emailIcon from '../assets/img/logos/svg/emailIcon.svg';

import './ContactInfo.css';

const emailTemplate = {
    mailto: 'contact@sustainabilityhub.co', //  '' or 'mail1' or 'mail1, mail2, mail3' [No trailing comma]
    cc: '', //  '' or 'mail1' or 'mail1, mail2' [No trailing comma at the end of the list]
    bcc: '', // '' or 'mail1' or 'mail1, mail2' [No trailing comma at the end of the list]
    subject: 'Inquiry From Sustainability Hub Website',
    body: 'Dear Sustainability Hub Team,%0D%0A%0D%0AI am reaching out to inquire about [specific topic or question]. I came across your '
        + 'platform and was particularly interested in [specific aspect or resource].%0D%0A%0D%0ACould you please provide more information '
        + 'on [specific request or question]? Additionally, I would appreciate any guidance on [related topic, if applicable].'
        + '%0D%0A%0D%0AThanks!%0D%0A[Your Name]%0D%0A[Your Affiliation, if applicable]%0D%0A[Your Contact Information]',
};

export default function ContactInfo() {
    const handleEmailUs = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    return (
        <div id="contactInfo" className="bg-primary">
            <div className="info-container">
                <Container onSubmit={handleEmailUs}>
                    <Row className="mb-3 align-items-center ">
                        <Col className="left-col p-0  d-flex flex-column ">
                            <div className="info-title h1 text-primary">Contact Us</div>
                            <div className="info-description h6 4">
                                <p>
                  Have a question? Want to learn more or get involved? <br />
                                    <br /> Send us an email to get in touch with us!
                                </p>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col-auto me-2 opacity-75 email-logo">
                                    <img src={emailIcon} alt="Email Icon" />
                                </div>
                                <div className="">
                                    <p className="mb-0 h6 opacity-75">Email</p>
                                    <div className="h3 mb-0 text-break">
                    contact@sustainabilityhub.co
                                    </div>
                                </div>
                            </div>
                            <a
                                href={`mailto:${emailTemplate.mailto}?cc=${emailTemplate.cc}&bcc=${emailTemplate.bcc}&subject=${emailTemplate.subject}&body=${emailTemplate.body}`}
                                id="btn-dont-show-on-mobile"
                            >
                                <Button className="email-us-btn" type="submit">
                  Email Us!
                                </Button>
                            </a>
                        </Col>

                        <Col className="d-flex p-0 sustainability-hub-logo col-lg-6 ">
                            <img
                                src={sustainabilityHubLogo}
                                alt="Sustainability Hub Logo"
                                style={{ width: '429px', height: '100%' }}
                                className="ms-auto float-right"
                            />
                        </Col>
                    </Row>
                    <a
                        href={`mailto:${emailTemplate.mailto}?cc=${emailTemplate.cc}&bcc=${emailTemplate.bcc}&subject=${emailTemplate.subject}&body=${emailTemplate.body}`}
                        id="btn-show-on-mobile"
                    >
                        <Button className="email-us-btn " type="submit">
              Email Us!
                        </Button>
                    </a>
                </Container>
            </div>
        </div>
    );
}
